@use "variables" as v;

.dashboard-map-container {
  background-color: v.$white;
  margin: 0px 1%;
  min-height: 95vh !important;
}

#bttn-carrier {
  color: v.$black;
  background-color: v.$white;
  border: 1px solid grey;
  transition: v.$transition-speed;

  &:hover {
    transition: v.$transition-speed;
    background-color: rgba(45, 143, 196, 0.6);
  }
}

#bttn-routes {
  color: v.$black;
  background-color: v.$white;
  border-bottom: 1px solid grey;
  border-top: 1px solid grey;
  transition: v.$transition-speed;

  &:hover {
    transition: v.$transition-speed;
    background-color: rgba(45, 143, 196, 0.6);
  }
}

#bttn-map {
  color: v.$white;
  background-color: v.$FG-Blue;
  border-bottom: 1px solid grey;
  border-top: 1px solid grey;
  transition: v.$transition-speed;

  &:hover {
    transition: v.$transition-speed;
    background-color: rgba(45, 143, 196, 0.6);
  }
}

#accordion-container {
  overflow-y: scroll;
  height: 91%;
}

/* MAP STYLING */
#dashboard-map-gc {
  display: flex;
  padding-left: 2%;
}

.form-container {
  width: 15.5vw;
  height: 80vh;
  display: flex;
  padding-top: 3%;
  align-items: flex-start;
}

@media (max-width:1440px) {
  .form-container {
    width: 15.5vw;
    height: 80vh;
    display: flex;
    padding-top: 3%;
    align-items: flex-start;
  }

  #dashboard-map-gc {
    display: flex;
    padding-left: 2%;
  }

  .date-picker {
    display: flex !important;
    flex-direction: column-reverse !important;
    align-items: center !important;
  }
}

.dashboard-map-form {
  background-color: #D3D3D3;
  padding: 1%;
  width: 120vw;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.map-group {
  display: flex !important;
  flex-direction: column !important;
}

.dashboard-checkbox {
  padding-top: 30px;
  width: 25% !important;
}

.date-picker {
  display: flex !important;
  justify-content: space-around !important;
  align-items: flex-end;
}

.label {
  align-items: center !important;
  width: auto;
}

.dropdown-style {
  min-height: 10px !important;
  margin: 23px 0px 0px 13px !important;
  width: 91% !important;
  display: flex !important;
  flex-wrap: wrap !important;
  flex-direction: row !important;
  justify-content: space-around !important;
  align-items: center !important;
}