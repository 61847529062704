@use "variables" as v;

.search-container {
    min-height: 80vh;
}

.search-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 20px;
    background-color: v.$white;
    width: 98%;
    margin-left: 1%;
    padding-bottom: 2.5%;
    align-items: center;

}

.search-card {
    width: 50% !important;
    align-items: center;
}

#search-content {
    background-color: v.$lightGrey
}

#search-grid-row {
    display: flex;
    align-items: center
}

#search-input {
    min-width: 35rem;
}

#search-button {
    min-width: 25rem;
    float: right
}

#tracking-message {
    min-height: 150;
    max-height: 250;
}