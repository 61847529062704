@use "variables" as v;

.wholeMap {
    display: flex !important;
    justify-content: center !important;
    padding: 1% 0 0 0% !important;
    margin-left: 5vw !important;
}

.map {
    width: 80vw;
    height: 85vh;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    padding: 5px;
}

@media (min-width:2560px) {
    .map {
        height: 80vh;
    }
}


@media (max-width:1440px) {
    .map {
        height: 80vh;
    }
}