.app {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
  }
  
  .search {
    padding: 10px;
    font-size: 20px;
    border: none !important;
  }
  
  .list {
    padding: 0;
    list-style: none;
    text-align: center;
  }
  
  .listItem {
    margin-bottom: 20px;
    font-size: 25px;
    color: #444;
    font-weight: 300;
  }
  
  table {
    width: 100%;
    margin: 50px 0px;
    border-spacing: 15px;
    color: #444;
  }
  
  tr {
    margin-bottom: 20px;
  }
  
  th {
    width: 250px;
    text-align: left;
    font-size: 20px;
  }
  
  td {
    width: 250px;
    font-size: 20px;
  }

  #openButton {
    text-decoration: underline;
    background-color: transparent;
    color: blue;
}