@use "variables" as v;
    
    .route-container{
        min-height: 100vh;
        margin:0px 20px;
        background-color: v.$white
    }
   
    .route-buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 7vh
    }
   
    #route-button{
        display: flex;
        border: 2px solid #D3D3D3;
        box-shadow: 3px 10px v.$black;
        font-size: 1.25rem
    }
 

    #route-table-header{
        text-align: center
    }

    #route-header-cell{
        background-color: v.$FG-Blue;
        color: v.$white;
        border: 0.5px solid v.$lightGrey;
    }

    #route-table-data{
        border: 0.5px solid v.$lightGrey
    }
   