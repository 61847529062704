#table {
    width: 100%;
    margin: 50px 0px;
    color: #444;
  }
  
  #tr {
    margin-bottom: 20px;
  }
  
  #th {
    width: 250px;
    text-align: left;
    font-size: 20px;
  }
  
  #td {
    width: 250px;
    font-size: 20px;
  }