@use "variables" as v;

.background-color {
    background-color: #38BDDE;

}

.carrier-container {
    margin: 0px 25px;
    padding-bottom: 0%;
    min-height: 100vh;
    background-color: v.$white;
    width: 98%;
    margin-left: 1%
}

.buttons-container {
    width: 100%;
    display: flex;
    padding: 1%;
    justify-content: center;
}

.filteringOptions {
    width: 100%;
    display: flex;
    padding: 1%
}

#table {
    width: 98%;
    margin-left: 1%
}

.display {
    padding: 0% 1%
}

.dropdown {
    padding: 0%
}

#btn-carrier {
    color: v.$white;
    background-color: v.$FG-Blue;
    border: 1px solid grey;
}

#btn-routes {
    color: v.$black;
    border-bottom: 1px solid grey;
    border-top: 1px solid grey;
    transition: v.$transition-speed;
    background-color: v.$white;

    &:hover {
        transition: v.$transition-speed;
        background-color: rgba(45, 143, 196, 0.6);
    }
}

#btn-map {
    color: v.$black;
    border: 1px solid grey;
    transition: v.$transition-speed;
    background-color: v.$white;

    &:hover {
        transition: v.$transition-speed;
        background-color: rgba(45, 143, 196, 0.6);
    }
}