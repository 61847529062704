@use "variables" as v;

#routes-container {
    background-color: v.$white;
    min-height: 90vh;
    margin: 0px 1%;
}

#routes-table {
    width: 98%;
    margin: 1%;
}

#parcel-view {
    color: v.$black;
    background-color: v.$white;
    border: 1px solid grey;
    transition: v.$transition-speed;

    &:hover {
        transition: v.$transition-speed;
        background-color: rgba(45, 143, 196, 0.6);
    }
}

#carrier-view {
    color: v.$white;
    background-color: v.$FG-Blue;
    border-bottom: 1px solid grey;
    border-top: 1px solid grey;

    &:hover {
        transition: v.$transition-speed;
        background-color: rgba(45, 143, 196, 0.6);
    }
}

#btn-map-view {
    color: v.$black;
    background-color: v.$white;
    border-bottom: 1px solid grey;
    border-top: 1px solid grey;
    border-right: 1px solid grey;
    transition: v.$transition-speed;

    &:hover {
        transition: v.$transition-speed;
        background-color: rgba(45, 143, 196, 0.6);
    }
}