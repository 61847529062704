@use "variables" as v;


.analytics-container{
    display: flex;
    justify-content: center;
    height: 80vh;
}
.report-style-class{
    width: 70%;
    height: 80vh;
}