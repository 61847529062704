@use "variables" as v;

.preferences-container{
    width: 98%;
    margin-left: 1%;
    display: flex;
    height: 137rem;
    justify-content: center;
    background-color: v.$white;
}
@media (min-width:940px) and (max-width:1600px) {
    .preferences-container{
        height: 129rem;
    }
}
@media (min-width:768px) and (max-width:939px) {
    .preferences-container{
        height: 140rem;
    }
}
@media (max-width:767px) {
    .preferences-container{
        height: 180rem;
    }
}
.preferences-box{
    width: 50%;
    height: 100vh;
    margin: 1%;
  
}

.preferences-header{
    float: left;
    font-weight: lighter;
}

#preferences-button{
    float: right;
    width: 20%;
    background-color: v.$FG-Blue;
    color: v.$white;
    margin-bottom: 2.5%
}

 /* CARD NUMBER 1 - ACCOUNT INORMATION */

#account-card{
    margin-top: 8%;
    width: 100%;
}

#account-card-content{
    background-color: v.$lightGrey
}

#account-info{
    padding: 2%;
    margin: 2%
}


#account-table{
    border: 2px solid grey;
    margin: 5%
}

#account-table-cell{
    border-right: 2px solid v.$lightGrey;
}


/* CARD NUMBER 2 - PORTAL SETTINGS */

    #portal-card{
        display: flex;
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
    }

    #portal-card-content{
        background-color: v.$lightGrey; 
        width: 100%;
    }

    #portal-title{
        width: 30%;
        display: flex;
        font-weight: bold;
    }

    #portal-content{
        width: 70%;
        display: flex;
    }

    #portal-dropdown{
        border: 2px solid v.$white;
        width: 100%;
        padding: 1%;
        border-radius: 5px;
    }

    #portal-menu{
        width: "100%"
    }

    /* CARD NUMBRER 3 - EXCEPTION TIME THRESHOLDS */

#exception-card{
    width: 100%;
}

#exception-table-cell{
    padding: 0%;
}

#exception-first{
    border: 2px solid v.$white;
    padding: 8%;
    width: 100%;
    background-color: #FFF899;
    margin: 0%;
}

#exception-second{
    border: 2px solid v.$white;
    padding: 8%;
    width: 100%;
    background-color: #FFE08C;
    margin: 0%;
}

#exception-content{
    width: 100%;
    padding: 3%;
    border: 2px solid v.$white;
}

#exception-input{
    width: 70px;
    padding: 1%;
}

// CARD 4

#onTime-card{
    width: 100%;
}

#onTime-header{
    font-size: 1.3rem;
    font-weight: bold;

}

#onTime-table-cell{
    padding: 0%;
}

#onTime-first{
    padding: 8%;
    width: 100%;
    margin: 0%;
    border: 2px solid v.$lightGrey;
    font-weight: bold;
    font-size: 1.1rem;
}

#section{
    width: 100%;
    margin: 1%
}

#neutral-icon{
    color: #31708f
}

#first-span{
    color:#31708f;
}

#onTime-second{
    padding: 8%;
    width: 100%;
    margin: 0%;
    border: 2px solid v.$lightGrey;
    font-weight: bold;
    font-size: 1.1rem;
}

#positive-icon{
    color: #3c763d;
}

#second-span{
    color: #3c763d;
}

#onTime-third{
    padding: 8%;
    width: 100%;
    margin: 0%;
    border: 2px solid v.$lightGrey;
    font-weight: bold;
    font-size: 1.1rem;
}
#negative-icon{
    color: #a94442;
}

#third-span{
    color: #a94442;
}

#onTime-content{
    width: 100%;
    padding: 3%;
    border: 2px solid v.$white;
   
}

#onTime-input{
    width: 70px;
    padding: 1%;
}

// CARD 5

#late{
background-color: #FFF899;
padding: 3%;
margin: 1%;
font-weight: bold;
}

#missed-stop{
background-color: #C8B6FC;
padding: 3%;
margin: 1%;
font-weight: bold;
}

#out-of-sequence{
background-color: #ECE5FF;
padding: 3%;
margin: 1%;
font-weight: bold;
}

#missing-POD{
background-color: #AFEEEE;
padding: 3%;
margin: 1%;
font-weight: bold;
}

#carrier-specific{
background-color: #B0CBFC;
padding: 3%;
margin: 1%;
font-weight: bold;
}


// -----------

Button{
    float: right;
}